import { get, post } from './aRequestFather'

import { baseUrl } from '@/environment/index.js'
// <------- 登录退出------->

//登录
// export const userLogin = params => get('/mobile-backend/user/login', params)
// //用户信息
// export const getUserInfo = params => get('/mobile-backend/user/getUserInfo', params)

// 获取用户信息
export const getUserInfo = params => get(`${baseUrl}/user/getUserInfo`, params)

// 根据zoneId获取小区获取单位信息
export const getZone = params => get(`${baseUrl}/visitorRecord/getZone`, params)

// 根据area获取小区
export const getAreaZone = params => get(`${baseUrl}/zone/byArea`, params)

// 获取访客码目的地选择列表
export const getCanUseZone = params => get(`${baseUrl}/visitorRecord/canUseZone`, params)

// 提交访客申请
export const createVisitor = data => post(`${baseUrl}/visitorRecord/create`, data)

// 获取健康码
export const getJkm = params => get(`${baseUrl}/user/jkm`, params)

// 获取已通过审核的健康码
export const getVisitList = params => get(`${baseUrl}/visitorRecord/tgList`, params)

// 获取家长孩子信息
export const getParentStudent = params => get(`${baseUrl}/school/parent/student`, params);

// 学生接送打卡
export const setStudentVisitor = params => post(`${baseUrl}/school/student/visitor`, params);

//获取验证码
// export const getLoginAuthCode = params => get('/mszj-web/common/getLoginAuthCode', params)

